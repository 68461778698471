























import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/AuthService";

interface Form {
  terminiDiServizio: boolean
}
@Component
export default class TerminiDiServizio extends Vue{
  public form: Form = {terminiDiServizio: false}

  async aggiornaTermini(){
    const response = await AuthService.terminiDiServizio()
    if(response.error){
      console.error(response.error)
    } else {
      sessionStorage.setItem("termini", "true")
      await this.$router.push("/");
    }
  }
}
